import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import { Fade, FadeUp } from "../../Fader";
import { CTA } from "../components/CTA";
import { HeroProps } from "./types";
import { ResponsiveImage } from "../components/ResponsiveImage";
import { Compliance } from "../../Compliance/Compliance";
const HeroBackdrop: React.FC<HeroProps> = ({
  fade = true,
  title,
  subTitle,
  actions,
  image,
  complianceLogos,
  topHero = true
}) => {
  const TitleTag = topHero ? "h1" : "h2";
  return <div className="relative flex flex-col justify-end overflow-hidden rounded-2xl pb-8 text-left md:min-h-[600px] md:items-start md:py-0" data-sentry-component="HeroBackdrop" data-sentry-source-file="HeroBackdrop.tsx">
      {image && <Fade enabled={fade} className="relative mb-6 aspect-video w-full md:absolute md:inset-0 md:mb-0 md:aspect-auto">
          <ResponsiveImage {...image} fit={"cover"} className="bg-center object-cover" layout="fill" priority={true} quality={100} />
        </Fade>}
      <div className="md:vertical-center z-20 px-5 sm:px-10 md:max-w-[90%] md:px-15 md:py-10 lg:max-w-[62%]">
        <FadeUp cascade enabled={fade} data-sentry-element="FadeUp" data-sentry-source-file="HeroBackdrop.tsx">
          <TitleTag className="text-e5 md:text-e4 lg:text-e3 max-w-[560px] text-beige-64 md:text-beige lg:whitespace-pre-wrap" {...toFieldPath(".title")} data-sentry-element="TitleTag" data-sentry-source-file="HeroBackdrop.tsx">
            {title}
          </TitleTag>
          <div>
            {subTitle && <div {...toFieldPath(".subTitle")} className="text-body-standard md:text-body-large whitespace-pre-wrap pt-6 text-beige-64 md:pt-4">
                {subTitle}
              </div>}
          </div>

          <div>
            {actions && <div className="flex flex-col items-center justify-center gap-2 pt-6 md:flex-row md:justify-start md:pb-4" {...toFieldPath(".actions")}>
                {actions.map((cta, idx) => <CTA cta={cta} key={cta._key} annotationPrefix={`.${idx}`} />)}
              </div>}
          </div>
        </FadeUp>
        {complianceLogos && <Compliance className="mt-8 opacity-60 md:absolute md:bottom-[52px] md:right-15 md:mt-0 md:opacity-100" variant="dark" />}
      </div>
    </div>;
};
export default HeroBackdrop;