import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import React from "react";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import Hero from "./Hero";
import { HeroCardSectionProps } from "./types";
import HeroBackdrop from "./HeroBackdrop";
const SectionHeroCard: React.FC<HeroCardSectionProps> = ({
  annotationPrefix,
  bgClassName,
  cardClassName,
  sectionVariant = "normal",
  section_id,
  bottomClassName,
  mobileLayout = "regular",
  ...heroProps
}) => {
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} bottomClassName={bottomClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionHeroCard" data-sentry-source-file="SectionHeroCard.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="SectionHeroCard.tsx">
        <div className={clsx("overflow-hidden rounded-2xl lg:py-0", {
        "bg-white": !cardClassName?.includes("bg-"),
        "py-6": !heroProps.backdropImageLayout,
        "pb-0": heroProps.image && mobileLayout === "regular",
        "pt-0": heroProps.image && mobileLayout === "reverse"
      }, cardClassName)}>
          {heroProps.backdropImageLayout && <HeroBackdrop {...heroProps} mobileLayout={mobileLayout} />}
          {!heroProps.backdropImageLayout && <Hero {...heroProps} mobileLayout={mobileLayout} inCard />}
        </div>
      </Container>
    </Section>;
};
export default SectionHeroCard;