import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import React from "react";
import { Fade, FadeUp } from "../../Fader";
import { CTA } from "../components/CTA";
import { ResponsiveImage } from "../components/ResponsiveImage";
import { HeroProps } from "./types";
const Hero: React.FC<HeroProps> = ({
  fade = true,
  topHero = true,
  mobileLayout = "regular",
  intro,
  title,
  subTitle,
  actions,
  inCard = false,
  image,
  className,
  alignment = undefined
}) => {
  const TitleTag = topHero ? "h1" : "h2";
  const alignmentChoice = alignment || (topHero ? "center" : "left");
  return <div className={clsx("grid lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:text-left gap-y-6 z-10 relative", {
    "text-center": alignmentChoice === "center",
    "text-left md:text-center": alignmentChoice === "left"
  }, className)} data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <div className={clsx("md:vertical-center md:py-10 relative z-10", {
      "px-5 sm:px-10 md:px-15": inCard,
      "mx-auto sm:px-15 lg:pl-0 lg:pr-15": !inCard
    })}>
        <FadeUp cascade enabled={fade} data-sentry-element="FadeUp" data-sentry-source-file="Hero.tsx">
          <div>
            {intro && <p {...toFieldPath(".intro")} className="text-body-standard mx-auto mb-2 opacity-80 md:mb-2">
                {intro}
              </p>}
          </div>
          <TitleTag className={clsx("md:whitespace-pre-wrap md:text-balance", {
          "text-e4 lg:text-e3": topHero,
          "text-e5 lg:text-e4": !topHero
        })} {...toFieldPath(".title")} data-sentry-element="TitleTag" data-sentry-source-file="Hero.tsx">
            {title}
          </TitleTag>
          <div>
            {subTitle && <div {...toFieldPath(".subTitle")} className="text-body-large mt-4 whitespace-pre-wrap md:mt-6">
                {subTitle}
              </div>}
          </div>

          <div>
            {actions && <div className={clsx("mt-6 flex flex-col items-center justify-center gap-2 md:flex-row md:justify-center lg:justify-start")} {...toFieldPath(".actions")}>
                {actions.map((cta, idx) => <CTA cta={cta} key={cta._key} annotationPrefix={`.${idx}`} className="w-full" />)}
              </div>}
          </div>
        </FadeUp>
      </div>
      {image && <Fade enabled={fade} className={clsx("self-stretch flex  aspect-[600/540] lg:aspect-auto lg:min-h-[540px] ", {
      relative: mobileLayout === "regular",
      "order-first lg:order-none relative": mobileLayout === "reverse",
      "absolute -bottom-6 inset-x-0 lg:relative lg:bottom-auto lg:inset-x-0": mobileLayout === "behind"
    })}>
          <ResponsiveImage {...image} width={600} height={540} layout="fill" position="right" className="object-cover" />
        </Fade>}
    </div>;
};
export default Hero;