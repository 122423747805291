import clsx from "clsx";
import Link from "next/link";
import { useCallback } from "react";
import { Button } from "./Button";
import { usePageLayoutActions } from "../../Layout/PageLayoutContext";
import { reportDemoLead } from "../../../lib/customerIO";
import { useCompanionSignupURL } from "../../../lib/useCompanionSignupURL";
import { toFieldPath } from "@stackbit/annotations";
const DEFAULT_CTA = "Book a Demo";
const DEFAULT_CTA_ID = "try_for_free";
type ButtonVariant = React.ComponentProps<typeof Button>["variant"];
export type CTA = null | {
  _key?: string;
  label: string;
  action: "book-demo" | "download-app" | "sign-up";
  variant?: ButtonVariant;
  partnerSlug?: string;
  id?: string;
  iconURL?: string;
} | {
  _key?: string;
  label: string;
  onClick: () => void;
  variant?: ButtonVariant;
  id?: string;
  iconURL?: string;
} | {
  _key?: string;
  label: string;
  href: string;
  variant?: ButtonVariant;
  id?: string;
  iconURL?: string;
};
type CTAProps = {
  cta: CTA;
  className?: string;
  annotationPrefix?: string;
};
export const CTA: React.FC<CTAProps> = ({
  cta,
  className,
  annotationPrefix
}) => {
  const {
    showDownloadBanner,
    onBookDemo
  } = usePageLayoutActions();
  const hasCTA = cta !== null;
  const label = cta?.label || DEFAULT_CTA;
  const id = cta?.id || DEFAULT_CTA_ID;
  const variant = cta.variant || "dark-fill";
  const iconURL = cta.iconURL;
  const partnerSlug = "partnerSlug" in cta ? cta.partnerSlug : undefined;
  const isSignUpCTA = "action" in cta && cta.action === "sign-up";
  const companionSignUpURL = useCompanionSignupURL(partnerSlug);
  const href = "href" in cta && cta.href.length > 0 ? cta.href : undefined;
  const linkTarget = isSignUpCTA ? href || companionSignUpURL : href;
  const isInternalLink = linkTarget?.startsWith("#");
  const smoothScroll = ev => {
    ev.preventDefault();
    document.querySelector("href" in cta && cta.href).scrollIntoView({
      behavior: "smooth"
    });
  };
  const ctaAction = useCallback(() => {
    if (cta) {
      if ("onClick" in cta) {
        cta.onClick();
        return;
      }
      if ("action" in cta) {
        switch (cta.action) {
          case "book-demo":
            reportDemoLead().finally(() => onBookDemo(label));
            break;
          case "download-app":
            showDownloadBanner(label);
            break;
        }
        return;
      }
    }
    reportDemoLead().finally(() => onBookDemo(label));
  }, [label, onBookDemo, cta, showDownloadBanner]);
  if (!hasCTA) return null;
  const buttonProps = {
    id,
    title: label,
    variant,
    iconURL,
    ...toFieldPath(annotationPrefix)
  };
  if (linkTarget) {
    return <Link href={linkTarget} passHref prefetch={false} className="w-full md:w-auto flex" onClick={isInternalLink ? smoothScroll : undefined} tabIndex={-1}>
        <Button {...buttonProps} className={clsx(className, "w-full")} />
      </Link>;
  }
  return <Button {...buttonProps} onClick={ctaAction} className={clsx(className, "w-full md:w-auto")} data-sentry-element="Button" data-sentry-component="CTA" data-sentry-source-file="CTA.tsx" />;
};