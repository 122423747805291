import clsx from "clsx";
import { LoadingAnimation } from "../../LoadingAnimation";
type Props = {
  title: string;
  variant: ButtonVariant;
  id?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button";
  iconURL?: string;
  loading?: boolean;
};
export type { Props as ButtonProps };
export function Button({
  className,
  id,
  title,
  variant,
  onClick,
  disabled = false,
  loading = false,
  type = "button",
  iconURL,
  ...props
}: Props) {
  const variantStyle = variants[variant];
  return <button {...props} id={id} type={type} disabled={disabled} onClick={onClick} className={clsx(["text-body-small font-medium", "min-w-36 inline-flex cursor-pointer items-center justify-center text-center", "select-none whitespace-nowrap ring-2 ring-inset", "h-11 rounded-full px-8", className, variantStyle])} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {loading ? <LoadingAnimation /> : <>
          {iconURL && <img src={iconURL} alt="" className="mr-2" />}
          {title}
        </>}
    </button>;
}
const variants = {
  "dark-fill": "text-beige bg-plum hover:bg-plum-80 active:bg-plum-64 focus:outline-black disabled:bg-plum-16 disabled:hover:bg-plum-16 disabled:text-plum-64 ring-transparent",
  "light-fill": "text-plum bg-beige-64 hover:bg-beige-64/80 focus:outline-black disabled:bg-beige-64/50 disabled:hover:bg-beige-64/50 disabled:text-beige-64 ring-transparent",
  "dark-bordered": "text-plum ring-plum bg-transparent hover:bg-plum-8 active:bg-plum-16 focus:outline-blue disabled:ring-plum-40 disabled:hover:ring-plum-40 disabled:hover:bg-transparent disabled:text-plum-64",
  "light-bordered": "text-beige-64 ring-beige-64 bg-transparent hover:bg-beige-64/20 active:bg-beige-64/80 focus:outline-blue disabled:ring-beige-64/80 disabled:hover:ring-beige-64/80 disabled:hover:bg-transparent disabled:text-beige-64/80"
};
type ButtonVariant = keyof typeof variants;