import { Buffer } from "buffer";
import { useRouter } from "next/router";
import { queryParams } from "./util";

const SIGN_UP_URL = `${process.env.NEXT_PUBLIC_WEB_COMPANION_BASE_URL}/auth/sign-up`;

export const useCompanionSignupURL = (
  partner?: string,
  flights?: Record<string, string>,
) => {
  const router = useRouter();
  return generateCompanionSignupURL({ ...router.query, fli: flights }, partner);
};

export const generateCompanionSignupURL = (params: any, partner?: string) => {
  const {
    utm_source,
    utm_campaign,
    utm_medium,
    utm_content,
    pid,
    cid,
    fli,
    referrer_page,
  } = params;
  const queryString = queryParams({
    partner,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_content,
    pid,
    cid,
    referrer_page,
    fli: fli && Buffer.from(JSON.stringify(fli)).toString("base64"),
  });

  return SIGN_UP_URL + queryString;
};
